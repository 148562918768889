/*
 * @Author: your name
 * @Date: 2020-12-18 13:47:12
 * @LastEditTime: 2021-03-24 17:03:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//引入recom组件！
const recom = {
  template: `<router-view></router-view>`,
};

const routes = [
  // 机器人
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    meta: { title: "广州华汇信息技术有限公司" },
  },
  {
    path: "/manual",
    name: "Home",
    component: () => import("@/views/cnc/manual"),
    meta: { title: "产品手册-伴学机器人" },
  },
  {
    path: "/sale",
    name: "Sale",
    component: () => import("@/views/cnc/sale"),
    meta: { title: "售后服务-伴学机器人" },
  },

  // 移动端软件下载
  {
    path: "/download/:lang",
    name: "download",
    component: () => import("@/views/download/download"),
  },

  // 电脑端软件下载
  {
    path: "/pcdownload",
    name: "pcdownload",
    component: () => import("@/views/download/pcDownload"),
  },
  // pdf文档
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("@/views/download/pdf"),
  },
  // 安卓版本
  {
    path: "/COMGO_Z1",
    name: "android",
    component: () => import("@/views/download/android"),
  },

  // 日文版本下载
  {
    path: "/japaneseDownload",
    name: "japaneseDownload",
    component: () => import("@/views/download/jpDownload"),
  },

  // 雕涂版本下载
  {
    path: "/dtDownload",
    name: "dtDownload",
    component: () => import("@/views/download/dtDownload"),
  },

  // mini雕刻机重做版
  {
    path: "/mini",
    component: recom, //引入recom,必不可少
    children: [
      {
        path: "agreement/:lang",
        name: "agreement",
        component: () => import("@/views/mini/agreement"),
        // meta: { title: "隐私政策" },
      },
      {
        path: "printHelp/:lang/:watt",
        name: "printHelp",
        component: () => import("@/views/mini/printHelp"),
        hidden: true,
        // meta: { title: "帮助中心" },
      },
      {
        path: "printSkill/:lang/:watt",
        component: () => import("@/views/mini/printSkill"),
        hidden: true,
        // meta: { title: "说明书（玩机技巧）" },
      },
    ],
  },

  // 龙门雕刻机
  {
    path: "/longmen",
    component: recom, //引入recom,必不可少
    children: [
      {
        path: "skill/:lang",
        name: "skill",
        component: () => import("@/views/longmen/skill"),
        // meta: { title: "隐私政策" },
      },
    ],
  },

  // tos 项目
  {
    path: "/tos",
    component: recom, //引入recom,必不可少
    children: [
      {
        path: "about",
        name: "about",
        component: () => import("@/views/tos/about"),
        meta: { title: "About Us" },
      },
      {
        path: "terms",
        name: "terms",
        component: () => import("@/views/tos/terms"),
        meta: { title: "TERMS AND CONDITIONS" },
      },
      {
        path: "policy",
        name: "policy",
        component: () => import("@/views/tos/policy"),
        meta: { title: "PRIVACY POLICY" },
      },
      {
        path: "test",
        name: "test",
        component: () => import("@/views/tos/test"),
        meta: { title: "时间" },
      },
    ],
  },

  // 拾音助手
  {
    path: "/pickup",
    component: recom, //引入recom,必不可少
    children: [
      {
        path: "agreement/:lang",
        name: "pickupAgreement",
        component: () => import("@/views/pickup/agreement"),
        // meta: { title: "隐私政策" },
      },
    ],
  },

  // 4g读证
  {
    path: "/4g",
    component: recom, //引入recom,必不可少
    children: [
      {
        path: "card",
        name: "card",
        component: () => import("@/views/4g/card"),
        // meta: { title: "隐私政策" },
      },
    ],
  },

  {
    path: "/404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router;
