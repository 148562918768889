/*
 * @Author: your name
 * @Date: 2020-12-18 13:47:12
 * @LastEditTime: 2020-12-21 15:50:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\main.js
 */
import "amfe-flexible/index.js";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import axios from "axios";
Vue.prototype.axios = axios;

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
Vue.config.productionTip = false;

// 请求
import { post, fetch, patch, put } from "./utils/http";
Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;

import { Col, Row, Form, Button, Field, Calendar, Empty, Toast } from "vant";
Vue.use(Col);
Vue.use(Row);
Vue.use(Form);
Vue.use(Button);
Vue.use(Field);
Vue.use(Calendar);
Vue.use(Empty);
Vue.use(Toast);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
